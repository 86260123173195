/* Global css */

*{
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
::-webkit-scrollbar{
  width: 4px;
  color:var(--color-secondary);
  transition: all .4s ease;
}
::-webkit-scrollbar-thumb {
  background: var(--color-secondary);
  border-radius: 4px;
  width: 4px;
  transition: all .4s ease;
}
::-webkit-scrollbar-track {
  background: #101010; 
  border-radius: 4px;
  width: 4px;
}

html{
  background: var(--color-background-alt);
  min-height: 100vh;
}

body{
  user-select: none;
  height: auto;
  overflow-x: hidden;
  width: 100%;
  background: var(--color-background-alt);
  min-height: 100vh;
}

.contentBlock {
  min-height: 100vh;
  position: relative;
}

.color{
  color: var(--color-secondary);
  transition: all .4s ease;
}
