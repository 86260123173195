.skill-html{
    height: auto;
    width: 100%;
    padding: 0%;
    position: relative;
    font-family: poppins;
    color:var(--color-texto-alt);
    display: inline-block;
    padding:1.5%;
}
.skill-text{
    width:80%;
    position: relative;
    height: 50px;
    color:var(--color-texto-title);
}
.html{
    display: inline-block;
    position: absolute;
    left: 5%;
    top:30%;
}
.html-prog{
   width:100%;
   height:40px;
   border-radius: 0px;
   background: var(--color-primary);
   box-shadow:0px 0px 25px rgb(0,0,0,.1);
   position: relative;
   overflow: hidden;
}
.html-progress{
    position: absolute;
    top:10%;
    left:1%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s ease;
    border-radius: 0px;
    background: var(--color-secondary);
}