#navigation-bar{
    position: fixed;
    height: 85px;
    width: 100%;
    z-index: 5;
    padding: 0 0;
    overflow: hidden;
    background-color: var(--color-background);
    transition: ease all .5s;
}

#navigation-bar.dark{
    background-color: transparent;
}

.navContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    height: 100%
}

.menuBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}
.menubar{
    /*position: absolute;
    right:5%;
    top: 50%;*/
    cursor: pointer;
    opacity: 1;
    transition: all .4s ease;
    /*transform: translate(0, -50%);*/
}
.menubar span{
    position: relative;
    background-color:transparent;
    height:2px;
    width: 20px;
    display: block;
    margin:6px;
    border-radius: 20px;
    transition: ease all .5s;
}
.menubar .first-span{
    width:35px;
}
.menubar .first-span::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    right:0;
    background-color:var(--color-primary);
    transition: all .5s ease;
}
.menubar .second-span{
    width:35px;
}
.menubar .second-span::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 75%;
    top:0;
    right:0;
    background-color:var(--color-secondary);
    transition: all .3s ease;
}
.menubar .third-span{
    width:35px;
}
.menubar .third-span::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 50%;
    top:0;
    right:0;
    background-color:var(--color-highlight);
    transition: all .3s ease;
}
.menubar:hover .third-span::before , .menubar:hover .second-span::before , .menubar:hover .first-span::before {
    width: 100%;
}

.open .menubar .second-span {
    width: 0;
}

.open .menubar .first-span::before, .open .menubar .third-span::before {
    width: 100%;
}

.open .menubar .first-span {
    transform: rotate(45deg) translate(11px, 1px);

} 
.open .menubar .third-span {
    transform: rotate(-45deg) translate(11px, -1px);
}

.close-first , .close-second{
    height: 2px;
    width: 35px;
    background-color: whitesmoke;
    display: block;
    margin: 4px;
    cursor: pointer;
    padding: 1px;
    border-radius: 20px;
   }
   .close-first {
       transform: rotate(45deg);
   }
   .close-second{
       transform: rotate(-45deg);
   }


.menubar:hover{
    opacity: 1;
}

.dark .menubar .first-span::before{
    background-color:white;
}

.dark .menubar .second-span::before{
    background-color:white;
}
.dark .menubar .third-span::before{
    background-color:white;
}

.dark .menubar:hover .third-span::before, .dark .menubar:hover .second-span::before, .dark .menubar:hover .first-span::before {
    background-color: var(--color-highlight);

}


#navigation-content .logo svg *, .dark .logo svg * {
    fill: white;
}

.barCta {
    color: var(--color-highlight);
    font-family: 'Covered By Your Grace', cursive;
    /*position: absolute; 
    right:calc(5% + 60px);
    top: 50%;*/
    transition: all .4s ease;
    transform: rotate(-7.5deg) translate(-15px, 10px);
    opacity: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    font-size: 15px;
}

.open .barCta, .dark .open .barCta {
    opacity: 0;
}

/*
@media only screen and (max-width: 560px){
    .barCta {
        right:20%;
    }
}
*/

.barCta .arrow {
    transform: rotate(15deg);
    animation: animateArrow 1s infinite ease;
}

.dark .barCta {
    opacity: 1;
}

@keyframes animateArrow{
    0%{
        transform: rotate(25deg) translate(0px, 0px);;
    }
    70%{
        transform: rotate(25deg) translate(5px, -5px);
    }
    100%{
        transform: rotate(25deg) translate(0px, 0px);;
    }
}