.contact-info{
    background: url(../../assets/img/contact-back.jpg);
    background-position: center;
    background-size: cover;
    border-radius: 0px;
    margin-bottom: 35px;
    position: relative;
    overflow: hidden;
    transition: ease all .5s;
    /*height: 100%;*/
}
.contact-info::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-background-alt);
    opacity: .8;
    pointer-events: none;
}
.contact-info-header{
    position: relative;
    z-index: 2;
    font-size: 24px;
    font-family: poppins;
    font-weight: 400;
    color: var(--color-highlight);
    padding: 30px;
}