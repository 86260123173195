.portfolio{
    height:auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 1.5%;
    padding-right: 2%;
    padding-top: 50px;
    padding-bottom: 40px;
}

.portfolio-text{
    width: 40%;
    padding-left: 1%;
    margin: 1%;
    margin-left: -40px;
    margin-top: 80px;
    z-index: 3;
    opacity: 0;
    background: var(--color-background-alt);
    padding-top: 10px;
    padding-bottom: 10px;
    transition: ease all .5s;
}



.portfolio-text h2{
    font-size: 35px;
    font-family: poppins;
    font-weight: 200;
    padding: 20px;
    color: var(--color-secondary);
    transition: all .4s ease;
}
.portfolio-text p{
    font-size: 16px;
    opacity: .8;
    font-family: poppins;
    font-weight: 200;
    padding: 20px;
}
.portfolio-text button{
    font-size: 16px;
    opacity: .8;
    font-family: poppins;
    font-weight: 200;
    padding: 10px 20px;
    background:transparent;
    color: var(--color-secondary);
    transition: all .4s ease;
    outline: none;
    margin: 20px;
    border: 1.5px solid var(--color-secondary);
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: all .4s ease;
}
.portfolio-text button::before{
    content: "";
    position: absolute;
    width: 0%;
    height: 200%;
    transform: rotate(-15deg) translateY(-20px);
    top: 0;
    left: 0;
    z-index: 3;
    background: var(--color-secondary);
    transition: all .7s ease;
}
.portfolio-text button:hover{
    opacity: 1;
    color:whitesmoke;
}
 button:hover .index{
     position: relative;
    z-index: 4;
}
.portfolio-text button:hover::before{
    opacity: 1;
    width: 120%;
}

    .portfolio-image img{
        /*height: 400px;*/
        width: 100%;
        position: relative;
    }
    .portfolio-image{
        height: 400px;
        width: 40%;
        transition: ease all .5s;
        opacity: 0;
    }


.portfolio-image img::before{
    content: "";
    height: 100%;
    width: 100%;
    background: var(--color-secondary);
    opacity: .1;
    top: 0;
    left: 0;
    transition: all .4s ease;
    position: absolute;
    z-index: 2;
}
#portfolio-content{
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}


.square {
    position: relative;
    width: 100%;
    background-color: black;
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 0px;
  }
  
  .square:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .img-portfolio {
    transition: ease all .5s;
  }

  .square:hover .content .img-portfolio {
    transform: scale(1.1);
    
  }
  
  .modal-content {
    border-radius: 0;
  }

  .info-portfolio {
    background: var(--color-primary-rgba-25);
    background: linear-gradient(0deg, var(--color-primary) 0%,  var(--color-primary-rgba-50) 20%, var(--color-primary-rgba-25) 100%);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: ease all .5s;
    opacity: 0;
    padding: 10px 15px;
  }
  .info-portfolio h3 {
    font-size: 20px;
  }

  .info-portfolio h4 {
    font-size: 16px;
    background-color: var(--color-highlight);
    border-radius: 0px;
    padding: 5px 10px 5px 10px;
    width: fit-content;
  }

  .square:hover .content .info-portfolio {
    opacity: 1;
  }

  .portfolio-title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 15px;
  }

  .portfolio-cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .carouselDescription {
    padding: 15px 0 0 0;
    font-size: 18px;
  }

  .carouselWraper {
    position: relative;
  }

  .modal-title {
    padding: 10px 15px;
    background-color: var(--color-primary-rgba-50);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  
  .modal-title span {
    background-color: var(--color-highlight);
    border-radius: 0px;
    padding: 5px 10px 5px 10px;
    width: fit-content;
  }

  .modalCTA {
    display: flex;
    align-items: flex-end;
    padding-top: 15px;
    flex-direction: column;
    justify-content: space-between;
  }

  @media only screen and (max-width: 991px){
    .portfolio-cta {
      top: 25%;
    }

    .carouselDescription {
      font-size: 16px;
    }

    .modalCTA {
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 767px){
    .portfolio-cta {
      top: 50%;
    }

    .modal-title {
      padding: 10px 0px;
      background-color: transparent;
      color: var(--color-primary);
      top: 100%;
      bottom: auto;
    }

    .modal-title.h4 {
      font-size: 18px;
    }

    .modal-title span {
      color: white;
    }

    .carouselDescription {
      padding: 55px 0 0 0;
      font-size: 14px;
    }

    .modalCTA {
      flex-direction: row;
    }
  }

