.footer{
    background: var(--color-primary);
    width: 100%;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    font-family: poppins;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
}
.footer .footer-text{
    position: relative;
    display: inline;
    font-size: 16px;
    font-weight: 400;
    opacity: 1;
}