#skills{
    width: 100%;
    height:auto;
}
.skills-header{
    height: auto;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-family: raleway;
    color: var(--color-texto-alt);
    padding:10px;
    padding-bottom: 30px;
    padding-top: 30px;
    font-weight: 900;
}
.skills-content{
    height: auto;
    width:100%;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
}