.cv {
    padding-top: 40px;
}

/* CSS */
.myButton {
  appearance: none;
  background-color: white;
  border: 2px solid var(--color-secondary);
  border-radius: 0px;
  box-sizing: border-box;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 0px;
  min-width: 0;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  transition: all .5s cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  will-change: transform;
}

.myButton:disabled {
  pointer-events: none;
}

.myButton:hover, .myButton:active {
  color: var(--color-primary);
  background-color: var(--color-highlight)!important;
  border: 2px solid var(--color-highlight)!important;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}
