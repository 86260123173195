#navigation-content{
    height: 100vh;
    width: 100%;
    position:fixed;
    z-index: 5;
    background-color:var(--color-primary);
    transform: translateY(-200%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.close-first , .close-second{
    height: 2px;
    width: 35px;
    background-color: whitesmoke;
    display: block;
    margin: 4px;
    cursor: pointer;
    padding: 1px;
    border-radius: 20px;
   }
   .close-first {
       transform: rotate(45deg);
   }
   .close-second{
       transform: rotate(-45deg);
   }

.navigation-close{
    position: absolute;
    top: 50%;
    right:4%;
    padding: 10px;
    cursor: pointer;
    transition: all.3s ease;
    opacity: .8;
    z-index: 3;
    transform: translate(0, -50%);

}

.navigation-close:hover{
    opacity: 1;
    transform: translate(0, -50%) rotate(90deg);
}

.navigation-links{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family:poppins;
    z-index: 2;
}
.navigation-links a{
    padding:10px;
    text-decoration: none;
    color: white;
    font-size: 30px;
    opacity: 1;
    transition: all .4s ease;
}

.navigation-links a.cta{
    color: var(--color-highlight)
}


.navigation-links a:hover{
    opacity: 1;
}
.navigation-links a::before{
    content: "";
    position: absolute;
    top:50%;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translate(-50%,-50%);
    align-items: center;
    font-size: 5em;
    font-weight: 400;
    font-family: monoton;
    color:rgb(255, 255, 255,.1);
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    letter-spacing: 100px;
    transition: all .4s ease;
}
.navigation-links a:hover::before{
    content: attr(data-text);
    opacity: 1;
    letter-spacing: 10px;
    text-align: center;
}

.navigation-links a::after{
    content: "";
    width: 0%;
    height: 2px;
    background: white;
    display: block;
    margin: 0 auto;
    transition: ease all .5s;
}

.navigation-links a.cta::after{
    background: var(--color-highlight);
}

.navigation-links a:hover::after{
    content: "";
    width: 100%;
}

.menuHeader {
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0; 
    left: 0;
    
position: fixed;
height: 100px;
width: 100%;
z-index: 1;
padding: 10px;
background: transparent;
overflow: hidden;
}
