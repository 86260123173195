#breaker{
    height: 100vh;
    width: 200%;
    background-color: var(--color-secondary);
    opacity: 1;
    animation: breakeranimate 2s linear;
    display: none;
    transition: all .4s ease;
    border-radius: 5px;
    z-index: 10;
    position: fixed;
}
@keyframes breakeranimate{
   0%{ transform : translateX(-100%)}
   50%{ transform : translateX(0%) }
   100%{ transform : translateX(100%) }
}
@-webkit-keyframes breakeranimate{
    0%{ transform : translateX(-100%) }
    50%{ transform : translateX(0%) }
    100%{ transform : translateX(100%) }
}