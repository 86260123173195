#header{
    background: var(--color-background-alt);
    height: 100vh;
    width: 100%;;
    overflow: hidden;
    position:relative;
    display: block;
    animation: scale 1.5s ease;
}

#header .container {
    position: relative;
    height: 100vh;
}

#header::before {
    content: "";
    background-image: url('../../assets/img/fondo.jpg');
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.15;
}

@keyframes scale{
    0%{
        transform: scale(.6);
    }
    100%{
        transform: scale(1);
    }
}

.header-image img{
    width: 70%;
    border-radius: 50%;
    animation: animateProfile 3s infinite;
    transition: ease all .5s;
    box-shadow: 10px 10px 15px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 15px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 15px 0px rgba(0,0,0,0.75);
}

@keyframes animateProfile {
    0%{
        transform: translate(5%, -5%)
    }
    70%{
        transform: translate(-5%, 5%)
    }
    100%{
        transform: translate(5%, -5%)
    }
}
.header-image{
    float: left;
    height: auto;
    background-size: cover;
    background-position: center;
    width: 35%;   
    height: 100vh;
    position: relative;

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

@media only screen and (max-width: 1199px){
    .header-image{
        width: 35%;   
        height: 100vh;
        margin-top: 0vh;
    }

    .header-image img{
        width: 60%;
    }
}

@media only screen and (max-width: 991px){
    .header-image{
        width: 35%;   
        height: 100vh;
        margin-top: 0vh;
    }

    .header-image img{
        width: 60%;
    }
}
@media only screen and (max-width: 767px){
    .header-image{
        width: 50%;   
        height: 75vh;
        margin-top: 25vh;
    }

    .header-image img{
        width: 50%;
    }
}
@media only screen and (max-width: 575px){
    .header-image{
        width: 100%;   
        height: 57vh;
        margin-top: 33vh;
        justify-content: center;
    }

    .header-image img{
        width: 40%;
    }
}


.header-content{
    height: 100vh;
    width: 100%;
    float: left;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: raleway;
    font-weight: 400;
    letter-spacing: 1px;
    flex-direction: column;
    font-size: 50px;
    z-index: 1;
}

@media only screen and (max-width: 575px){
    .header-content{
        height: 57vh;
    }
}

.header-content-box{
    z-index: 2;
    text-align: center;
    height: auto;
    width: auto;

}
.header-content .firstline{
    z-index: 2;
    font-weight:700;
    font-family: poppins;
    font-size: 65px;
}
.header-content .secondline{
    z-index: 2;
    font-size: 30px;
    padding-top:20px ;
    font-weight:500;
    color: rgb(255, 255, 255,1);
}

.headerCTA {
    padding: 35px 0;
    color: white;
    font-family: raleway;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0px);
    width: max-content;
    z-index: 3;
}

.headerCTA .myButton {
    margin-top: 15px;
}

@media only screen and (max-width: 991px){
    .header-content .firstline{
        font-size:52px;
    }
    .header-content .secondline{
        font-size: 26px;
        padding-top:0px ;
    }

    .headerCTA {
        padding: 25px 0;
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px){
    .header-content .firstline{
        font-size:46px;
    }
    .header-content .secondline{
        font-size: 20px;
        padding-top:0px ;
    }

    .headerCTA {
        padding: 18px 0;
        font-size: 16px;
        bottom: 60px;
    
    }
}

@media only screen and (max-width: 560px){
    .header-content .firstline{
        font-size: 32px;
    }
    .header-content .secondline{
        font-size: 15px;
        padding-top:0px ;
    }

    .headerCTA {
        padding: 15px 0;
        font-size: 14px;
    }
}


.slash{
    animation-name: animateslash;
    animation-duration: .8s;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
@keyframes animateslash{
    0%{
        opacity: 1;
        z-index: 2;
    }
    100%{
        opacity: 0;
        z-index: 0;
    }
}



@media only screen and (max-width: 767px){

}

.headerCTA-link {
    color: var(--color-highlight);
    transition: ease all .5S;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}

.headerCTA-link:hover {
    color: var(--color-secondary);
}