#services{
    position: relative;
}

.services-heading{
    height: auto;
   
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-family: raleway;
    color: whitesmoke;
    padding:10px;
    padding-bottom: 30px;
    padding-top: 30px;
    font-weight: 900;

}

.services-content{
    height: auto;
   
    overflow: visible;
    text-align: center;
    padding:2.5% 0;
}

