#contact{
    display: none;
    height: auto;
    text-align: center;
    width: 100%;
    background: var(--color-background);
    position: relative; 
}
.contact-header{
    height: auto;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-family: raleway;
    color: whitesmoke;
    padding:10px;
    padding-bottom: 30px;
    padding-top: 60px;
    font-weight: 900;
}
.contact-header-caption{
    padding: 10px;
    color: whitesmoke;
    opacity: .9;
    font-family:raleway;
    font-size: 18px;
}
.contact-content{
    margin: auto;
    height: auto;
    padding-bottom: 80px;
}