#portfolio-content{
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 20px;
}
#portfolio{
    display: none;
    height: auto;
    overflow-x: hidden;
    background-color: var(--color-background);
    width: 100%;
    color:whitesmoke;
}

.portfolio-header{
    height: auto;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-family: raleway;
    transition: all .4s ease;
    color: whitesmoke;
    padding:10px;
    padding-bottom: 30px;
    padding-top: 60px;
    font-weight: 900;
}