.contact-form{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;

}
form{
    display: flex;
    flex-direction: column;
    height: auto;
    width:auto;
    align-items: center;
    position: relative;
}
.input-line{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.input-name{
    
    padding:10px 20px;
    border-radius: 0px;
    outline: none;
    background: var(--color-background-input);
    border:none;
    color: var(--color-texto);
    font-family: poppins;
    font-size: 18px;
}
.form-header{
    font-size: 24px;
    font-family: poppins;
    font-weight: 400;
    color: var(--color-texto);
    padding: 28px;
}
.input-subject{
    
    padding:10px 20px;
    border-radius: 0px;
    outline: none;
    background: var(--color-background-input);
    border:none;
    color: var(--color-texto);
    font-family: poppins;
    font-size: 18px;
}
.input-textarea{
    width: 84%;
    padding:10px 20px;
    margin:15px;
    height: 150px;
    border-radius: 0px;
    outline: none;
    background: var(--color-background-input);
    border:none;
    color: var(--color-texto);
    font-family: poppins;
    font-size: 18px;
}

.formMessage {
    width: 100%;
}

.formMessage div {
    width: 100%;
    padding: 15px 0;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    margin-top: 15px;
}

.formMessage div.error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}

.formMessage div.sucsess {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}

form .myButton {
  margin: 0 auto;
}

.divCaptcha {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emailError {
    color: red;
}