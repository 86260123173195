.block-header{
    height: auto;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-family: raleway;
    color: var(--color-texto-title);
    padding:10px;
    padding-bottom: 10px;
    padding-top: 60px;
    font-weight: 900;
    transition: ease all .5s;
    opacity: 0;
}
.block-header-caption{
    padding: 10px;
    color: var(--color-texto-title);
    opacity: .9;
    font-family:raleway;
    font-size: 18px;
}

@media only screen and (max-width: 767px){
    .block-header{
        font-size: 32px;
    }
    .block-header-caption{
        font-size: 15px;
    }
}

@media only screen and (max-width: 560px){
    .block-header{
        font-size: 26px;
    }
    .block-header-caption{
        font-size: 12px;
    }
}