.service{
    height:100%;
    margin: 15px 0;
    background-color: var(--color-primary);
    position: relative;
    text-align: center;
    z-index: 2;
    border-radius: 0px;
    overflow: hidden;
    /*animation: opacity 1.4s ease ;*/
    transition: ease all .5s;
}
.service-img{
    text-align: center;
}

.service-img img{
    display: inline-block;
    z-index: 2;
    position: relative;
    margin: 20px;
    border-radius: 30%;
    padding: 10px;
    border: 0px solid var(--color-highlight);
    background: var(--color-highlight);
    box-shadow:0px 0px 0px 0px var(--color-highlight);
    height:100px;
    transition: all .8s ease;
}


@media only screen and (max-width: 991px){

    .service-img img{
        margin: 10px;
        height: 75px;
    }
}

@media only screen and (max-width: 767px){

    .service {
        height: auto;
        margin: 15px 0;
    }
    
    .service-img img{
        margin: 10px;
        height: 50px;
    }
}

@media only screen and (max-width: 575px){

    .service {
        height: auto;
        margin: 15px 0;
    }
    .service-img img{
        margin: 15px;
        height: 75px;
    }
}

.service:hover {
    box-shadow:0px 0px 25px rgb(0,0,0,.5);
}

.service:hover .service-img img{
    box-shadow:0px 0px 0px 400px var(--color-secondary);
    background: var(--color-secondary);
}
.service-description{
    font-family: poppins;
    color:var(--color-texto-alt);
    position: relative;
    display: inline-block;
    z-index:2;
}
.service-description h2{
    padding: 10px;
    margin: 5px;
    font-weight: 500;
    font-size: 25px;
}
.service-description p{
    padding: 10px;
    font-weight: 300;
    font-size: 16px;
    opacity: 1;
    text-align: left;
    padding: 0 15px;
}

@media only screen and (max-width: 767px){

    .service-description h2{
        padding: 10px;
        margin: 5px;
        font-weight: 200;
        font-size: 18px;
    }
    .service-description p{
        padding: 10px;
        font-weight: 200;
        font-size: 12px;
        opacity: 1;
    }
}