.contact-info-content-line{
    height: auto;
    padding: 10px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin-left: 100px;
}

@media only screen and (max-width: 1199px){
    .contact-info-content-line{
        margin-left: 50px;
    }
}

@media only screen and (max-width: 991px){
    .contact-info-content-line{
        margin-left: 15px;
    }
}

@media only screen and (max-width: 767px){
    .contact-info-content-line{
        margin-left: 100px;
    }
}

@media only screen and (max-width: 575px){
    .contact-info-content-line{
        margin-left: 15px;
    }
}

.contact-info-icon-text{
    color: white;
    padding:15px;
    font-family: poppins;
}
.contact-info-icon-text h6{
    font-size: 15px;
    opacity: 1;
    margin-bottom: 0;
}
.contact-info-icon-text a{
    font-size: 14px;
    opacity: 1;
    margin-bottom: 0;
    color: white;
    transition: ease all .5s;
    text-decoration: none;
}

.contact-info-icon-text a:hover{
    color: var(--color-highlight);
}

.icon{
    height:40px;
    width: 40px;
    opacity: 1;
}

.icon svg {
    width: 100%;
    height: 100%;
}

.icon svg * {
    fill: white
}


