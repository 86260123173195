@import url('https://fonts.googleapis.com/css?family=Raleway:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap');

html {
    --color-primary: #00003D;
    --color-secondary: #306ab2;
    --color-highlight: #ff9100;
    
    --color-texto: #212121;
    --color-texto-title: #00003D;
    --color-texto-alt: #FAFAFA;

    --color-background: #ECEFF1;
    --color-background-alt: #00003D;
    --color-background-input: #CFD8DC;

    --color-primary-rgba-100: rgba(0, 0, 61, 1);
    --color-primary-rgba-75: rgba(0, 0, 61, .75);
    --color-primary-rgba-50: rgba(0, 0, 61, .50);
    --color-primary-rgba-25: rgba(0, 0, 61, .25);

    --color-secondary-rgba-100: rgba(48, 106, 178, 1);
    --color-secondary-rgba-75: rgba(48, 106, 178, .75);
    --color-secondary-rgba-50: rgba(48, 106, 178, .5);
    --color-secondary-rgba-25: rgba(48, 106, 178, .25);
}