.social-media-links{
    height: 100vh;
    position: absolute;
    z-index: 2;
    padding: 15px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 0;
}
.social-media{
    color: white;
    width:  21px;
    ;
    opacity: .8;
    margin: 8px;
    transition: all .4s ease;
}
.social-media:hover{
    opacity: 1;
    transform: scale(1.1);
}

.social-media svg * {
    transition: ease all .5s;
    fill : white;
}

.social-media:hover svg * {
    fill : var(--color-highlight);
}

.contact{
    display: flex;
    position: absolute;
    width: 10%;
    right: 0;
    top: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
}
.contact a{
    z-index: 3;
}
.contact img{
    margin:10px;
    height:30px;
    opacity: .6;
    transition: all .4s ease;
}
.contact img:hover{
    opacity: 1;
    transform: scale(1.1);
}


@media only screen and (max-width: 767px){
    .social-media-links{
        width: 100%;
        left: 0;
        bottom: 0;
        position: absolute;
        padding: 20px;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .contact{
        width: auto;
        right: 0;
        bottom: 0; 
        top: auto;
        position: absolute;
        padding: 20px;
        height: auto;
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }


}

/*

.social-media-links{
    height: 100vh;
    position: absolute;
    z-index: 2;
    padding: 60px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.social-media-links{
        width: 100%;
        left: 0;
        bottom: 0;
        position: absolute;
        padding: 10px;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }


*/