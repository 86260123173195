.contentBlock {
    padding-top: 20px;
}

.contentBlock.header {
    padding-top: 0;
}

#about{
    background-color: var(--color-background);
    display: none;
    width: 100%;
    height: auto;
    overflow: hidden;
}

#about-content{
    display: flex;
    flex-direction:column;
    align-items: center;
    color: var(--color-texto);
    justify-content: center;
    height: auto;
    padding:0px;
}
.about-header{
    height: auto;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-family: raleway;
    color: var(--color-texto);
    padding:10px;
    padding-bottom: 30px;
    padding-top: 60px;
    font-weight: 900;
}
.about-first-paragraph{
    color: var(--color-texto-title);
    justify-content: center;
    font-size: 20px;
    font-family: poppins;
    padding: 15px 15px;
    font-weight: 400;
}

.about-first-line{
    font-size: 35px;
    opacity: 1;
    margin-bottom: 10px;
    display: block;
}


.about-second-line{
    opacity: 1;
    font-size: 18px;
    font-weight: 200;
    color: var(--color-texto);
}

@media only screen and (max-width: 991px){
    .about-first-line{
        font-size: 30px;
    }

    .about-second-line{
        font-size: 16px;
    }
  
}

@media only screen and (max-width: 767px){
    .about-first-line{
        font-size: 26px;
    }

    .about-second-line{
        font-size: 14px;
    }
  
}


@media only screen and (max-width: 575px){
    .about-first-line{
        font-size: 22px;
    }

    .about-second-line{
        font-size: 12px;
    }
  
}

.about-main{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    flex-direction: row;
}
.about-img img{
    border-radius: 0px;
}
.about-img {
    height:auto;
    padding-top: 25px;
    border-radius: 0px;
}

