.logo {
    height: 80px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    /*position: absolute;
    left:5%;
    top: 50%;
    transform: translate(0, -50%);*/
    cursor: pointer;
    overflow: hidden;
}


@media only screen and (max-width: 1199px){
    .logo {
        height: 70px;
    }
}

@media only screen and (max-width: 991px){
    .logo {
        height: 60px;
    }
}

@media only screen and (max-width: 767px){
    .logo {
        height: 50px;
    }
}

@media only screen and (max-width: 575px){
    .logo {
        height: 40px;
    }
}

.logo svg, .logo svg * {
    height: 80%;
    transition: all .4s ease;
}

.logo .isotipo {
    z-index: 2;
}

.logo .logotipo {
    transition: ease all .5s;
    opacity: 0;
    transform: translate(-100%, 0);
}

.logo:hover .logotipo {
    opacity: 1;
    transform: translate(0, 0);
}
